import React from 'react';
import { Link } from "react-router-dom";
import store, { authStatus } from '@app/store'

export default ({children, ...props}) => {

	const { state } = store()

	return state.auth.status === authStatus.LOGGEDIN
		? <Link {...props}>{children}</Link>
		: null
}