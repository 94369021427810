import React from 'react';
import store, { authStatus } from '@app/store'
import { Icon, Tooltip } from 'antd'
import GoogleLogin from 'react-google-login';

const DefaultButton = ({icon='login', status='', tooltip, onClick=()=>{}, disabled=false, }) => {
	let el = <Icon className={`login-button -status-${status}`} type={icon} onClick={onClick} disabled={disabled} />
	return tooltip
		? <Tooltip title={tooltip} placement="bottomRight" overlayClassName={'auth-tooltip'}>{el}</Tooltip>
		: el
}

const successTooltipText = profile => `${profile.name}\n${profile.email}\n`


export default ({buttons={}, onSuccess=()=>{}, onFailure=()=>{}}) => {
	
	const { state, trigger } = store()
	
	return <GoogleLogin
			clientId={state.auth.clientId}
			buttonText="Auth"
			onRequest={e => {
				trigger('auth.request')
			}}
			onSuccess={response => {
				trigger('auth.success', response)
				typeof onSuccess === "function" && onSuccess(response)
			}}
			onFailure={response => {
				trigger('auth.failure', response)
				typeof onSuccess === "function" && onFailure(response)
			}}
			cookiePolicy={'single_host_origin'}
			hostedDomain={'envirosuite.com'}
			render={renderProps => {

				let el = null

				switch (state.auth.status) {
					case authStatus.PROCESSING:
						el = buttons.processing ? buttons.processing : <DefaultButton icon="loading" status={authStatus.PROCESSING}/>
						break;
					case authStatus.LOGGEDIN:
						renderProps.onClick = () => {}
						el = buttons.success ? buttons.success : <DefaultButton icon="check-circle" status={authStatus.LOGGEDIN} tooltip={successTooltipText(state.auth.credentials.profileObj)}/>
						break;
					case authStatus.ERROR:
						el = buttons.error ? buttons.error : <DefaultButton icon="login" status={authStatus.ERROR}/>
						break;
					default:
						el = buttons.default ? buttons.default : <DefaultButton icon="login" status={authStatus.LOGGEDOUT} tooltip='Login with your Envirosuite account'/>
						break;
				}

				return React.cloneElement(el, { onClick: renderProps.onClick, disabled: renderProps.disabled })
			}}
		/>
}