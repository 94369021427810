import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { AppContainer } from 'react-hot-loader';
import App from '@app';

// style
import '@style/global.scss';
import '@style/components.scss';
import '@style/overrides.scss';
import 'antd/dist/antd.css';

ReactDOM.render(
	<AppContainer>
		<Router>
		   <App/>
		</Router>
	</AppContainer>, 
	document.getElementById('root')
);

if (module.hot) {
	module.hot.accept('./app/index.jsx', () => {
		const NextApp = require('./app/index.jsx').default; // eslint-disable-line global-require
		ReactDOM.render(
			<AppContainer>
				<Router>
					<NextApp/>
				</Router>
			</AppContainer>,
			document.getElementById('root')
		);
	});
}