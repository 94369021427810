import { useEffect, useState } from 'react'
import { get, cloneDeep } from 'lodash'

const stores = {}

const storeController = (name, config) => {
	let state = cloneDeep(config.initialState) || {}
	let triggers = config.triggers || {}
	let setters = config.setters || {}
	let functions = config.functions || {}
	let init = typeof config.init === 'function' ? config.init : s => s.state
	let ready = typeof config.ready === 'function' ? config.ready : s => s.state
	let subscriptions = []
	let initialised = false
	let onStateUpdate = typeof config.onStateUpdate === 'function' ? config.onStateUpdate : s => s

	let dispatchAction = (actions, action, state, payload, set, trigger) => {
		let cb = get(actions, action)
		let result = (typeof cb === "function") ? cb({state: state, set: set, trigger: trigger}, payload) : state
		let newState = result || state
		onStateUpdate({state: newState, name: name})
		return {...newState}
	}

	stores[name] = {
		init: (set, trigger) => {
			if(initialised !== true){
				let initState = init({state: state, set: set, trigger: trigger, name: name, config: config})
				state = typeof initState === 'undefined' ? state : initState
				ready({state: state, set: set, trigger: trigger, name: name, config: config})
				initialised = true
			}
		},
		set: (action, payload) => {
			state = dispatchAction(setters, action, state, payload, stores[name].set, stores[name].trigger)
			subscriptions.forEach(sub => sub(state))
		},
		trigger: (action, payload) => {
			dispatchAction(triggers, action, state, payload, stores[name].set, stores[name].trigger)
		},
		call: (action, payload) => {
			let cb = get(functions, action)
			return (typeof cb === "function") ? cb({state: state, set: stores[name].set, trigger: stores[name].trigger}, payload) : null
		},
		getState(key) {
			return key ? get(state, key) : state
		},
		subscribe(updater) {
			subscriptions.push(updater)
		},
		unsubscribe(updater) {
			subscriptions = subscriptions.filter(sub => sub !== updater)
		},
	}

	return stores[name] 
}

export const fetchStore = (name, config) => {
	let storeName = name || 'store'
	return !stores[storeName] ? storeController(storeName, config) : stores[storeName]
}

export const useStore = (name, config={}) => {
	let store = fetchStore(name, config)

	const [state, setState] = useState(store.getState())
	
	const set = (action, payload) => store.set(action, payload, store.set, store.trigger)
	const trigger = (action, payload) => store.trigger(action, payload, store.set, store.trigger)
	const call = (action, payload) => store.call(action, payload, store.set, store.trigger)

	store.init(set, trigger)
	
	useEffect(() => {
		store.subscribe(setState)
		return () => store.unsubscribe(setState)
	}, [])

	return {
		state: state, 
		set: set, 
		trigger: trigger,
		call: call
	}
}