import React, { useEffect, useMemo, Fragment, useRef } from 'react';
import { Icon } from 'antd'
import { Map, EVSLogo } from '@components'
import { Marker } from '@components/map'
import store, { fetchingStatus } from '@app/store'

const FullContent = (props) => <Fragment>
	<Fragment>
		<EVSLogo type='minimal'/>
		<span className='title'>{props.title}</span>
		<span className='subtitle'>
			{props.address}
			{props.streetviewlink && <a href={props.streetviewlink} className='streetview' target='_blank' rel="noopener noreferrer"><Icon type='picture'/></a>}
		</span>
		{props.phone && <span className='phone'><Icon type="phone" /> <a href={`tel:${props.phone}`}>{props.phone}</a></span>}
		{props.fax && <span className='fax'><Icon type="printer" /> <a href={`tel:${props.fax}`}>{props.fax}</a></span>}
		{props.email && <span className='email'><Icon type="mail" /> <a href={`mailto:${props.email}`} target='_blank' rel="noopener noreferrer">{props.email}</a></span>}
		{props.info && <span className='info'>{props.info}</span>}
		<hr/>
		{props.contact.name && <span className='title'>{props.contact.name}</span>}
		{props.contact.role && <span className='subtitle'>{props.contact.role}</span>}
		{props.contact.phone && <span className='phone'><Icon type="phone" /> <a href={`tel:${props.contact.phone}`}>{props.contact.phone}</a></span>}
		{props.contact.fax && <span className='fax'><Icon type="printer" /> <a href={`tel:${props.contact.fax}`}>{props.contact.fax}</a></span>}
		{props.contact.email && <span className='email'><Icon type="mail" /> <a href={`mailto:${props.contact.email}`} target='_blank' rel="noopener noreferrer">{props.contact.email}</a></span>}
		{props.contact.info && <span className='info'>{props.contact.info}</span>}
	</Fragment>
</Fragment>

const PartialContent = ({title}) => <Fragment>
	<span className='title'>{title}</span>
</Fragment>

export default () => {
	const { state, trigger } = store()
	const mapRef = useRef();
	
	useEffect(() => trigger('offices.fetch'), [state.offices.status === fetchingStatus.INITIALISED])
	
	return useMemo(()=>{
		return <Map
			ref={mapRef}
			showNavigation={true}
			isLoading={state.offices.status === fetchingStatus.LOADING}>
			
			{state.offices.items.map((item, i) => {
				return <Marker
					key={i}
					lat={item.lat}
					lon={item.lon}
					onClick={data=>mapRef.current.flyTo({...data, zoom: 12})}
					className={'-office'}
					contentHover={<PartialContent {...item}/>}
					contentOpen={<FullContent {...item}/>}
				/>
			})}
		</Map>
	}, [state.offices.status])
}