import React, { useEffect, useMemo, Fragment, useRef } from 'react';
import { Map } from '@components'
import { Marker } from '@components/map'
import store, { fetchingStatus } from '@app/store'

const FullContent = (props) => <Fragment>
	<span className='title'>{props.title}</span>
	<span className='title'>{props.title}</span>
	<span className='title'>{props.title}</span>
</Fragment>

const PartialContent = ({title}) => <Fragment>
	<span className='title'>{title}</span>
</Fragment>

export default () => {
	const { state, trigger } = store()
	const mapRef = useRef();

	useEffect(() => trigger('clients.fetch'), [state.auth.status, state.clients.status === fetchingStatus.INITIALISED])

	return useMemo(()=>{
		return <Map
			ref={mapRef}
			showNavigation={true}
			isLoading={state.clients.status === fetchingStatus.LOADING}>

			{state.clients.items.map((item, i) => {
				return <Marker
					key={i}
					lat={item.lat}
					lon={item.lon}
					onClick={data=>mapRef.current.flyTo({...data, zoom: 12})}
					className={'-client'}
					contentHover={<PartialContent {...item}/>}
					contentOpen={<FullContent {...item}/>}
				/>
			})}
		</Map>
	}, [state.clients.status])
}