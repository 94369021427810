import React from 'react';
import { Route, withRouter } from "react-router-dom";
import { Modal, Icon } from 'antd'
import store, { authStatus } from '@app/store'
import { LoginButton } from '@components'

export default withRouter(({component: Component, redirect='/', history, ...rest}) => {

	const { state } = store()

	return <Route {...rest} render={props => {
	
		if(state.auth.status === authStatus.LOGGEDIN){
			return <Component {...props}/>
		}else{
			return <Modal
				visible={true}
				okText={
					<LoginButton buttons={{
						default: <div><Icon type='login'/> Login now</div>,
						processing: <div><Icon type='loading'/> Logging in...</div>,
					}}/>
				}
				cancelText={[<Icon type='arrow-left' key='1'/>, 'Take me home']}
				onCancel={() => history.push('/')}
				>
				<h3><Icon type='warning'/> Login required</h3>
				<p>Login with your <strong>Envirosuite Account</strong> to view this page</p>
			</Modal>
		}
	}} />
})