import React, { useState, Fragment } from 'react';
import { Link, withRouter } from "react-router-dom";
import { Avatar, Icon, Drawer, Popover } from 'antd'
import store, { authStatus } from '@app/store'
import AuthLink from '@app/authlink'
import { LoginButton, LogoutButton, Search } from '@components'
import Logo from '@images/logo_full.svg'

const UserNav = withRouter(({name, imageUrl, email, history}) => {
	
	const { trigger } = store()

	const content = <Fragment>
		<span className={'name'}><Avatar src={imageUrl} size="small"/> {name}</span>
		<span>{email}</span>
		<hr/>
		<LogoutButton onSuccess={()=>history.replace('/')}>
			<Icon type='logout'/> Logout
		</LogoutButton>
		<AuthLink onClick={() => trigger('clearcache')}>
			<Icon type='delete'/> Clear Cache
		</AuthLink>
	</Fragment>

	return <Popover content={content} placement="bottomRight" trigger="click" overlayClassName='user-nav' arrowPointAtCenter={true}>
		<Icon type="user" />
	</Popover>
})

const Menu = withRouter(({location, history}) => {

	const [open, setOpen] = useState(false)
	
	return <Fragment>
		<Icon type='menu' onClick={() => setOpen(true)}/>
		{location.pathname !== '/' && <span className="breadcrumbs">{location.pathname.split('/').join(' / ')}</span>}
		<Drawer
			title='Menu'
			placement="left"
			onClose={() => setOpen(false)}
			onClick={() => setOpen(false)}
			visible={open}
			className={'global-nav'}
		>
			<Link exact to={'/'}><Icon type='bank'/> Our Offices</Link>
			<AuthLink exact to={'/staff'}><Icon type="usergroup-add" /> Our People</AuthLink>
			<AuthLink exact to={'/clients'}><Icon type='environment'/> Our Clients</AuthLink>
		</Drawer>
	</Fragment>
})


export default ({location, history}) => {
	
	const { state } = store()
	const authed = state.auth.status === authStatus.LOGGEDIN

	return <header className={'global-header'}>
		<span>
			{authed ? <Menu/> : <LoginButton/>}
		</span>
		<span>
			<Logo className={'logo'}/>
		</span>
		<span>
			{authed && <Search/>}
			{authed && <UserNav {...state.auth.credentials.profileObj}/>}
		</span>
	</header>
}