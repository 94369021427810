import { useStore } from '@util/store'
import { get, cloneDeep } from 'lodash'
import { message } from 'antd'
import request from '@app/request'
//import { createBrowserHistory } from 'history';

const GOOGLE_AUTH_CLIENT_ID = process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID

export const fetchingStatus = {
	INITIALISED: 'initialised',
	LOADING: 'loading',
	COMPLETE: 'complete',
	ERROR: 'error'
}

export const authStatus = {
	LOGGEDOUT: 'loggedout',
	PROCESSING: 'processing',
	LOGGEDIN: 'loggedin',
	ERROR: 'error'
}

const initialState = {
	clients: {
		status: fetchingStatus.INITIALISED,
		items: [],
		filtering: {},
		labels: false,
	},
	offices: {
		status: fetchingStatus.INITIALISED,
		items: [],
	},
	staff: {
		status: fetchingStatus.INITIALISED,
		items: [],
	},
	isEmbedded: window.location !== window.parent.location,
	auth: {
		clientId: GOOGLE_AUTH_CLIENT_ID,
		credentials: {},
		status: authStatus.LOGGEDOUT,
	},
	endpoint: null
}

const triggers = {
	auth: {
		request: ({set}) => {
			set('auth.status', authStatus.PROCESSING)
		},
		success: ({state, set}, response) => {
			set('auth.success', response)
			set('auth.status', authStatus.LOGGEDIN)
			message.success('Logged in')
		},
		failure: ({set}, response) => {
			set('auth.error', response)	
			set('auth.status', authStatus.ERROR)
			message.error('Login error')
		},
		logout: ({trigger, set}, callback) => {
			typeof callback === "function" && callback()
			set('auth.logout')	
			set('auth.status', authStatus.LOGGEDOUT)
			message.success('Logged out')
		},
	},
	offices: {
		fetch: ({state, set}, opts) => {
			set('offices.status', fetchingStatus.LOADING)
		
			state.endpoint
			.fetch('offices')
			.then(data => {
				set('offices.items', data.items)
				set('offices.status', fetchingStatus.COMPLETE)
			})
			.catch(err => {
				message.error(err)
				set('offices.status', fetchingStatus.ERROR)
			})
		}
	},
	clients: {
		fetch: ({state, set}, opts, history) => {
			set('clients.status', fetchingStatus.LOADING)
		
			state.endpoint
				.fetch('clients', {'Authorization-Token': get(state, 'auth.credentials.Zi.id_token', null)})
				.then(data => {
					set('clients.items', data.items)
					set('clients.status', fetchingStatus.COMPLETE)
				})
				.catch(err => {
					message.error(err)
					set('clients.status', fetchingStatus.ERROR)
				})
		}
	},
	staff: {
		fetch: ({state, set}, opts, history) => {
			set('staff.status', fetchingStatus.LOADING)
		
			state.endpoint
				.fetch('staff', {'Authorization-Token': get(state, 'auth.credentials.Zi.id_token', null)})
				.then(data => {
					set('staff.items', data.items)
					set('staff.status', fetchingStatus.COMPLETE)
				})
				.catch(err => {
					message.error(err)
					set('staff.status', fetchingStatus.ERROR)
				})
		}
	},
	clearcache: ({state, set}) => {
		state.endpoint
			.fetch('clear-cache', {'Authorization-Token': get(state, 'auth.credentials.Zi.id_token', null)}).then(() => {
				message.success('Cache cleared')
				set('reset')
			})
	}
}

const setters = {
	offices: {
		items: ({state}, items=[]) => {
			state.offices.items = items
			return state
		},
		status: ({state}, status) => {
			state.offices.status = status
			return state
		},
	},	
	clients: {
		items: ({state}, items=[]) => {
			state.clients.items = items
			return state
		},
		status: ({state}, status) => {
			state.clients.status = status
			return state
		},
	},
	staff: {
		items: ({state}, items=[]) => {
			state.staff.items = items
			return state
		},
		status: ({state}, status) => {
			state.staff.status = status
			return state
		},
	},
	auth: {
		success: ({state}, credentials) => {
			state.auth.credentials = credentials
			return state
		},
		status: ({state}, status) => {
			state.auth.status = status
			return state
		},
		logout: ({state}, response) => {
			state.auth = cloneDeep(initialState.auth)
			return state
		}
	},
	reset: ({state}) => {
		state.clients = cloneDeep(initialState.clients)
		state.offices = cloneDeep(initialState.offices)
		return state
	}
}

export default () => useStore('appstore', {
	initialState: initialState,
	triggers: triggers,
	setters: setters,
	init: ({state}) => {
		request.init({endpoint: process.env.REACT_APP_API_ENDPOINT})
		state.endpoint = request 
	}
})