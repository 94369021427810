import React, { useEffect, useMemo, Fragment, useRef } from 'react';
import { groupBy } from 'lodash'
import { Icon, Divider } from 'antd'
import { Map } from '@components'
import { Marker } from '@components/map'
import store, { fetchingStatus } from '@app/store'

const FullContent = ({title, people}) => {
	const groups = groupBy(people, person => person.division)
	return <Fragment>
		<Icon type="usergroup-add" />
		<span className='title'>{title}</span>
		
		{Object.keys(groups).map(groupname => {
			let people = groups[groupname]
			return <span className="department">
			<hr/>
				<span className="subtitle">{groupname}</span>
				{people.map(person => {
					return <span className="person">
						<strong>{person.name}</strong>
						<Divider type="vertical" /> 
						<span>{person.position}</span>
						<Divider type="vertical" />
						{person.phone && <a href={`tel:${person.phone}`}><Icon type="phone" /></a>}
						{person.fax && <a href={`tel:${person.fax}`}><Icon type="printer" /></a>}
						{person.email && <a href={`mailto:${person.email}`} target='_blank' rel="noopener noreferrer"><Icon type="mail" /></a>}
						{/*<span className="contact">
							{person.phone && <span className='phone'><a href={`tel:${person.phone}`}><Icon type="phone" /> Phone</a></span>}
							{person.fax && <span className='fax'><a href={`tel:${person.fax}`}><Icon type="printer" /> Fax</a></span>}
							{person.email && <span className='email'><a href={`mailto:${person.email}`} target='_blank' rel="noopener noreferrer"><Icon type="mail" /> Email</a></span>}
						</span>*/}
					</span>
				})}

				
			</span>
		})}

		{/*people.map(person => {
			return <span className='person'>
				<span className="title">{person.name} / {person.role}</span>
			</span>
		})*/}
	</Fragment>
}

const PartialContent = ({title, people}) => <Fragment>
	<span className='title'>{people.length} staff @ {title}</span>
</Fragment>

export default () => {
	const { state, trigger } = store()
	const mapRef = useRef();

	useEffect(() => trigger('offices.fetch'), [state.auth.status, state.offices.status === fetchingStatus.INITIALISED])

	return useMemo(()=>{
		return <Map
			ref={mapRef}
			showNavigation={true}
			isLoading={state.offices.status === fetchingStatus.LOADING}>

			{state.offices.items.map((item, i) => {
				return <Marker
					key={i}
					lat={item.lat}
					lon={item.lon}
					onClick={data=>mapRef.current.flyTo({...data, zoom: 12})}
					className={'-staff'}
					contentHover={<PartialContent {...item}/>}
					contentOpen={<FullContent {...item}/>}
				/>
			})}
		</Map>
	}, [state.offices.status])
}