import React from 'react';
import store from '@app/store'
import { Icon } from 'antd'
import { GoogleLogout } from 'react-google-login';

export default ({children={}, onSuccess=()=>{}, onFailure=()=>{}}) => {
	
	const { state, trigger } = store()

	return <GoogleLogout
		clientId={state.auth.clientId}
		buttonText="Logout"
		onLogoutSuccess={() => {
			trigger('auth.logout', onSuccess)
		}}
		render={renderProps => {
			return <a href='#logout' onClick={renderProps.onClick} disabled={renderProps.disabled}>
				{children || <Icon className="logout-button" type={'logout'}/>}
			</a>
		}}
	/>
}