import React, { useState } from 'react';
import store from '@app/store'
import { AutoComplete, Input, Icon } from 'antd'

const SearchState = {
	READY: 'ready',
	SEARCHING: 'searching',
	NORESULTS: 'noresults',
	ERROR: 'error'
}

export default () => {
	
	const { state } = store()
	const [datasource, setDatasource] = useState([])
	const [searchState, setSearchState] = useState(SearchState.READY)
	const [timer, setTimer] = useState(SearchState.READY)

	const handleSearch = value => {
		if(value.length < 3) {
			setSearchState(SearchState.READY)
			return
		}
		
		clearTimeout(timer)
		setTimer(setTimeout(()=>{
			setSearchState(SearchState.SEARCHING)

			state.endpoint
				.fetch(`search?term=${value}`)
				.then(data => {
					setDatasource(!value ? [] : data.items||[])
					setSearchState(SearchState.READY)
				})
				.catch(err => {
					setDatasource([])
					setSearchState(SearchState.NORESULTS)
				})
		}, 500))

		
	};

	return <AutoComplete
		className={`global-search -status-${searchState}`}
		dataSource={datasource}
		placeholder="try to type `b`"
		onSearch={handleSearch}
		size={'small'}
		>
		<Input suffix={searchState === SearchState.SEARCHING ? <Icon type="loading"/> : (searchState === SearchState.NORESULTS ? <Icon type="warning"/> : <Icon type="search"/>)} />
	</AutoComplete>
	
}