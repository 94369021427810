let opts = {
	endpoint: null,
}

const init = (o={}) => opts = Object.assign({}, opts, o)

const apicall = (route, headers={}) => {
	let headerObj = new Headers()
	Object.keys(headers).forEach( key => {
		headerObj.set(key, headers[key])
	})

	return new Promise((resolve, reject) => {
		fetch(`${opts.endpoint}/${route}`, {headers: headerObj})
			.then(response => {
				if(response.status !== 200) {
					reject(response.statusText)
					return
				}
				if(!response.json) {
					reject(response.statusText)
					return
				}
				resolve(response.json())
			})
	});
}

const main = () => ({
	init: init,
	fetch: apicall
})

export default main()