import React, { Fragment } from 'react';
import { Route, Redirect } from "react-router-dom";
import { Clients, Offices, Staff } from '@views'
import Header from '@app/header'
import AuthRoute from '@app/authroute'

export default () => {
	return <Fragment>
		<Header/>
		<Route exact path="/" render={()=><Redirect to='/offices' />}/>
		<Route path="/offices" component={Offices}/>
		<AuthRoute path="/clients" component={Clients}/>
		<AuthRoute path="/staff" component={Staff}/>
		<Route component={() => <Redirect to="/"/>}/>
	</Fragment>
}