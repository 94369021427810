import React, { useState, forwardRef, useImperativeHandle } from 'react';
import MapGl, { Marker as ReactMarkerGl, NavigationControl, FlyToInterpolator } from 'react-map-gl';
import { Icon } from 'antd'
import mapStyle from './style.json'
import 'mapbox-gl/dist/mapbox-gl.css';
import './style.scss'

const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN
const REACT_APP_MAP_LATITUDE = parseInt(process.env.REACT_APP_MAP_LATITUDE)
const REACT_APP_MAP_LONGITUDE = parseInt(process.env.REACT_APP_MAP_LONGITUDE)
const REACT_APP_MAP_ZOOM = parseFloat(process.env.REACT_APP_MAP_ZOOM)
const REACT_APP_MAP_PITCH = parseInt(process.env.REACT_APP_MAP_PITCH)

let immutableViewport = {
	width: "100%",
	height: "100%",
	style: {'position': 'absolute'},
	bearing: 0,
	maxPitch: REACT_APP_MAP_PITCH||0,
	minPitch: REACT_APP_MAP_PITCH||0,
}

let dynamicViewportDefaults = {
	latitude: REACT_APP_MAP_LATITUDE||0,
	longitude: REACT_APP_MAP_LONGITUDE||0,
	zoom: REACT_APP_MAP_ZOOM||1.3,
}

export const Marker = ({lat, lon, className, contentHover, contentOpen, onClick}) => {
	
	let [isOpen, setOpen] = useState(false)
	const handleClick = () => {
		onClick({latitude: parseFloat(lat), longitude: parseFloat(lon)})
		setOpen(true)
	}

	const handleMouseLeave = () => {
		setTimeout(()=>setOpen(false), 300)
	}

	if(!lat || !lon) return null
	
	return <ReactMarkerGl latitude={parseFloat(lat)} longitude={parseFloat(lon)} className={`marker`}>
		<div className={`marker-icon ${className}`} data-open={isOpen} onClick={handleClick} onBlur={handleMouseLeave} tabIndex="-1">
			{contentHover && <span className="marker-popup -popup-hover">{contentHover}</span>}
			{contentOpen && <span className="marker-popup -popup-open">{contentOpen}</span>}
		</div>
	</ReactMarkerGl>
}


const MapBase = ({children, showNavigation=false, className='', isLoading=false}, ref) => {

	const [dynamicViewport, updateDynamicViewport] = useState(dynamicViewportDefaults)
	const [transitionType, setTransitionType] = useState({})

	useImperativeHandle(ref, () => ({
		flyTo: ({speed, ...coords}) => {
			if(!coords || typeof coords !== 'object') return

			setTransitionType({
				transitionDuration: speed||1000,
				transitionInterpolator: new FlyToInterpolator()
			})

			updateDynamicViewport({...dynamicViewport, ...coords})
			setTimeout(()=>setTransitionType({}), 100)
		}
	}));

	return <MapGl
		{...dynamicViewport}
		{...immutableViewport}
		{...transitionType}
		mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
		mapStyle={mapStyle}
		onViewportChange={data => updateDynamicViewport(data)}
		onContextMenu={e=>console.log(e)}
		className={`map-container ${className} ${isLoading === true ? '-is-loading' : ''}`}>
		{isLoading && <Icon type='loading'/>}
		{children}
		{showNavigation && <NavigationControl showCompass={false}/>}
	</MapGl>
}

export default forwardRef(MapBase)